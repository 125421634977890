import { Buildings, Gear, Receipt, Sparkle, User } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import TitledPage from "../../Components/TitledPage";
import ListContainer from "../../UIKit/List/List";
import { ItemType, ListItem } from "../../UIKit/ListItem/ListItem";
import { useFeatureFlag } from "../../Hooks/useFeatureFlag";

export const AccountPage = () => {
  const navigate = useNavigate();
  const isOrganisationsEnabled = useFeatureFlag("organisations", false);

  const items = [
    {
      id: "personal_settings",
      type: ItemType.Header,
      label: "Personal Settings",
    },
    {
      id: "personal_details",
      type: ItemType.Item,
      icon: User,
      label: "Personal Details",
      description:
        "See and update your personal details, including billing information.",
    },
    {
      id: "app_settings",
      type: ItemType.Item,
      icon: Gear,
      label: "App Settings",
      description: "Configure your personal app settings.",
    },
  ];

  if (isOrganisationsEnabled) {
    items.push(...[
      {
        id: "organisation_settings",
        type: ItemType.Header,
        label: "Organization Settings",
      },
      {
        id: "my_organisations",
        type: ItemType.Item,
        icon: Buildings,
        label: "Organizations",
        description: "View and manage your organizations.",
      }
    ])
  }
  

  const onItemSelected = (index: number) => {
    const item = items[index];

    if (item.id === "personal_details") {
      navigate(`personal-details`);
    } else if (item.id === "app_settings") {
      navigate(`settings`);
    } else if (item.id === "my_organisations") {
      navigate(`organisations`);
    }
  };

  return (
    <TitledPage isResponsive={false} title={"Account"}>
      <ListContainer>
        {(items ?? []).map((item, index) => {
          return (
            <ListItem
              key={index}
              onClick={() => onItemSelected(index)}
              item={item}
            />
          );
        })}
      </ListContainer>
    </TitledPage>
  );
};
