import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import WhatsNewCarousel from "../../Pages/Marketing/WhatsNewCarousel";
import shallow from "zustand/shallow";
import { useEffect } from "react";
import { useSettingsStore } from "../../Store/SettingsStore";
import { useUserId } from "../../Store/AuthStore";

const Announcement = () => {
  const version = 1
  const userId = useUserId();

  const { whatsNewAnnouncementId, setWhatsNewAnnouncementId } = useSettingsStore(
    (state) => ({
      whatsNewAnnouncementId: state.whatsNewAnnouncementId,
      setWhatsNewAnnouncementId: state.setWhatsNewAnnouncementId
    }),
    shallow
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (version > whatsNewAnnouncementId && !isOpen && userId) {
      onOpen();
    }
  }, [whatsNewAnnouncementId])

  const onCloseAnnouncement = () => {
    setWhatsNewAnnouncementId(version);
    onClose();
  }

  return (
    <Modal size={"5xl"} isOpen={isOpen} onClose={onCloseAnnouncement}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <WhatsNewCarousel />
      </ModalContent>
    </Modal>
  );
};

export default Announcement;
