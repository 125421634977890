import useMethods from "../../useMethods";
import { User } from "../Users/UsersApiTypes";
import {
  CreateProjectRequest,
  Project,
  UpdateProjectRequest,
} from "./ProjectsApiTypes";

const useProjectsApi = () => {
  const methods = useMethods();

  const list = async (options: {
    userId?: string;
    search?: string;
    teamId?: string;
    organisationId?: string;
  }): Promise<Project[]> => {
    const { userId, organisationId, teamId, search = "" } = options;

    const result = await methods.get(
      organisationId
        ? `${
            process.env.REACT_APP_API_URL
          }/organisations/${organisationId}/teams/${teamId}/projects${
            search ? `?search=${search}` : ``
          }`
        : `${process.env.REACT_APP_API_URL}/users/${userId}/projects${
            search ? `?search=${search}` : ``
          }`
    );
    return result?.data;
  };

  const listUsers = async (options: {
    teamId?: string;
    organisationId?: string;
    projectId: string;
    search?: string;
  }): Promise<User[]> => {
    const { teamId, organisationId, projectId, search = "" } = options;

    const result = await methods.get(
      `${
        process.env.REACT_APP_API_URL
      }/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/members${
        search ? `?search=${search}` : ``
      }`
    );
    return result?.data;
  };

  const get = async (options: {
    userId: string;
    projectId: string;
    organisationId?: string;
    teamId?: string;
  }): Promise<Project> => {
    const { userId, projectId, organisationId, teamId } = options;

    let url = organisationId
      ? `organisations/${organisationId}/teams/${teamId}/projects/${projectId}`
      : `users/${userId}/projects/${projectId}`;

    const result = await methods.get(`${process.env.REACT_APP_API_URL}/${url}`);
    return result?.data;
  };

  const create = async (options: {
    userId: string;
    project: CreateProjectRequest;
  }): Promise<Project> => {
    const { userId, project } = options;

    let url = project.organisationId
      ? `organisations/${project.organisationId}/teams/${project.teamId}/projects`
      : `users/${userId}/projects`;

    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/${url}`,
      project
    );
    return result?.data;
  };

  const update = async (options: {
    userId: string;
    project: UpdateProjectRequest;
  }): Promise<Project> => {
    const { userId, project } = options;

    let url = project.organisationId
      ? `organisations/${project.organisationId}/teams/${project.teamId}/projects/${project.id}`
      : `users/${userId}/projects/${project.id}`;

    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/${url}`,
      project
    );

    return result?.data;
  };

  const deleteOne = async (options: {
    userId: string;
    projectId: string;
    organisationId?: string;
    teamId?: string;
  }): Promise<Project> => {
    const { userId, organisationId, teamId, projectId } = options;

    let url = organisationId
      ? `organisations/${organisationId}/teams/${teamId}/projects/${projectId}`
      : `users/${userId}/projects/${projectId}`;

    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/${url}`
    );
    return result?.data;
  };

  return {
    list,
    create,
    update,
    get,
    deleteOne,
    listUsers,
  };
};

export default useProjectsApi;
