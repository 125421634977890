import { TrashSimple, Link as LinkIcon, CaretDown } from "phosphor-react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  IconButton,
  Input,
  HStack,
  Collapse,
  Image,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { format, parseISO } from "date-fns";
import { formatTime } from "../../../../Utilities/TimeUtility";
import { TextInput } from "../../../../UIKit/TextInput/TextInput";
import { Entry, EntryLink } from "../../../../Api/Resources/Entries/EntriesApiTypes";
import AsyncSelect from "react-select/async";
import useEntryMutations from "../../../../Api/Resources/Entries/EntriesMutations";
import { usePlatformStore } from "../../../../Store/PlatformStore";
import shallow from "zustand/shallow";
import useProjectsApi from "../../../../Api/Resources/Projects/ProjectsApi";
import useCategoriesApi from "../../../../Api/Resources/Categories/CategoriesApi";
import { customSelectStyles } from "../../../../Theme";
import { SingleDatePicker } from "../../../../UIKit/DatePicker";
import styles from "./ModifyEntriesModal.module.scss";
import { useUserId } from "../../../../Store/AuthStore";
import getRandomKey from "../../../../Utilities/RandomKey";

type CustomProjectOption = {
  label: string;
  value: string;
  data: {
    organisationId: string | undefined;
    teamId: string | undefined;
  };
};

const getFaviconUrl = (url: string) => {
  try {
    const urlObj = new URL(url);
    return `https://www.google.com/s2/favicons?domain=${urlObj.hostname}&sz=32`;
  } catch {
    return null;
  }
};

export const ModifyEntriesModal = ({
  isOpen,
  onClose,
  selectedDate,
  order,
  entry,
}: {
  isOpen: boolean;
  onClose: () => void;
  selectedDate: Date;
  order: number;
  entry?: Entry;
}) => {
  const userId = useUserId();

  const isUpdating = useMemo(() => {
    return entry !== undefined;
  }, [entry]);

  const [selectedProject, setSelectedProject] =
    useState<CustomProjectOption | null>();

  const [selectedCategory, setSelectedCategory] = useState<{
    value: string;
    label: string;
  } | null>();

  const [categoryKey, setCategoryKey] = useState<string>(); // Used to force reload categories.
  const [description, setDescription] = useState<string>();
  const [time, setTime] = useState<string>();
  const [startDate, setStartDate] = useState<Date>();
  const [links, setLinks] = useState<EntryLink[]>([]);
  const [newLinkUrl, setNewLinkUrl] = useState("");
  const [newLinkTitle, setNewLinkTitle] = useState("");
  const projectsApi = useProjectsApi();
  const categoriesApi = useCategoriesApi();

  const { createEntry, updateEntry, deleteEntry } = useEntryMutations();

  const [showLinks, setShowLinks] = useState(false);

  const onModifyEntry = async () => {
    if (startDate) {
      if (isUpdating) {
        await updateEntry.mutateAsync({
          entry: entry!,
          projectId: selectedProject?.value,
          categoryId: selectedCategory?.value,
          organisationId: selectedProject?.data.organisationId,
          teamId: selectedProject?.data.teamId,
          userId,
          description,
          startDate,
          time: time ? time : "00:00",
          links,
        })
      } else {
        await createEntry.mutateAsync({
          projectId: selectedProject?.value,
          categoryId: selectedCategory?.value,
          organisationId: selectedProject?.data.organisationId,
          teamId: selectedProject?.data.teamId,
          userId,
          description,
          startDate,
          time: time ? time : "00:00",
          order,
          links,
        });
      }

      onPrepareModalClose();
    } else {
      throw new Error("No start date exists.");
    }
  };

  const onDeleteEntry = async () => {
    if (entry) {
      await deleteEntry.mutateAsync({
        entryId: entry.id!,
        userId,
        organisationId: entry.project?.organisationId,
        teamId: entry.project?.teamId,
      });
      onPrepareModalClose();
      return;
    }

    throw new Error("No entry to delete.");
  };

  const { platform } = usePlatformStore(
    (state) => ({ platform: state.platform }),
    shallow
  );

  useEffect(() => {
    if (entry) {
      setDescription(entry.description);
      setTime(formatTime(entry.time));
      setStartDate(parseISO(entry.date));
      setLinks(entry.links || []);

      const { category, project } = entry;

      if (category) {
        setSelectedCategory({
          label: category.name,
          value: category.id!,
        });
      }

      if (project) {
        const selectedProject = {
          label: project.name,
          value: project.id!,
          data: {
            organisationId: project.organisationId ?? undefined,
            teamId: project.teamId ?? undefined,
          },
        };

        setSelectedProject(selectedProject);
      }
    } else {
      setStartDate(selectedDate);
      setLinks([]);
    }
  }, [selectedDate, entry]);

  const loadProjects = (
    inputValue: string,
    callback: (options: CustomProjectOption[]) => void
  ) => {
    projectsApi
      .list({ userId: userId!, search: inputValue })
      .then((projects) => {
        const result = projects.map((project) => {
          let option: CustomProjectOption = {
            label: project.name,
            value: project.id!,
            data: {
              organisationId: project.organisation?.id,
              teamId: project.team?.id,
            },
          };

          return option;
        });

        callback(result);
      });
  };

  const loadCategories = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    if (!selectedProject) {
      throw new Error("No project selected");
    }

    categoriesApi
      .list({
        projectId: selectedProject?.value!,
        search: inputValue,
        userId,
        teamId: selectedProject?.data.teamId,
        organisationId: selectedProject?.data.organisationId,
      })
      .then((categories) => {
        const result = categories.map((category) => {
          return { label: category.name, value: category.id! };
        });

        callback(result);
      });
  };

  const onAddLink = () => {
    if (newLinkUrl) {
      setLinks([...links, { url: newLinkUrl, title: newLinkTitle }]);
      setNewLinkUrl("");
      setNewLinkTitle("");
    }
  };

  const onRemoveLink = (index: number) => {
    setLinks(links.filter((_, i) => i !== index));
  };

  const onPrepareModalClose = () => {
    setDescription(undefined);
    setSelectedCategory(null);
    setSelectedProject(null);
    setTime(undefined);
    setLinks([]);
    onClose();
  };

  return (
    <>
      <Modal
        size={{ md: "lg", sm: "full" }}
        isCentered
        isOpen={isOpen}
        onClose={onPrepareModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {`${isUpdating ? "Update" : "Create New"}`} Entry
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack width={"100%"} spacing={2}>
              <AsyncSelect<CustomProjectOption>
                styles={customSelectStyles}
                isClearable
                placeholder="Select Project"
                value={selectedProject}
                onChange={(value) => {
                  if (value !== selectedProject) {
                    setSelectedCategory(null);
                    setCategoryKey(getRandomKey(5));
                  }

                  setSelectedProject(value);
                }}
                cacheOptions
                defaultOptions={true}
                loadOptions={loadProjects}
              />
              {selectedProject && (
                <AsyncSelect
                  key={categoryKey}
                  styles={customSelectStyles}
                  isClearable
                  placeholder="Select Category"
                  value={selectedCategory}
                  onChange={(value) => setSelectedCategory(value)}
                  defaultOptions={true}
                  loadOptions={loadCategories}
                />
              )}
              <TextInput
                onChange={(e: any) => setDescription(e.target.value)}
                value={description}
                type="textarea"
                placeholder="Description"
                label="Description"
                id="description"
              />
              <Flex>
                <SingleDatePicker
                  className={styles.entriesDatePicker}
                  date={startDate}
                  onDateChange={(date: Date) => setStartDate(date)}
                  id="date"
                />
                <Box width={"16px"} />
                <TextInput
                  sx={{ height: "60px", fontSize: "18px" }}
                  value={time}
                  onChange={(e: any) => setTime(e.target.value)}
                  placeholder="00:00"
                  label="Time"
                  id="time"
                />
              </Flex>
              <Box paddingTop={2}>
                <Button
                  variant="ghost"
                  size="sm"
                  width="100%"
                  justifyContent="space-between"
                  onClick={() => setShowLinks(!showLinks)}
                >
                  <Text fontWeight="semibold" fontSize="14px">Links</Text>
                  <CaretDown
                    weight="bold"
                    style={{
                      transform: showLinks ? "rotate(180deg)" : "none",
                      transition: "transform 0.2s",
                    }}
                  />
                </Button>
                <Collapse in={showLinks}>
                  <Stack spacing={2} mt={2}>
                    {links.map((link, index) => (
                      <HStack key={index}>
                        <Box paddingLeft={3} flex={1}>
                          <HStack spacing={2}>
                            {getFaviconUrl(link.url) && (
                              <Image
                                src={getFaviconUrl(link.url)!}
                                alt="favicon"
                                width="16px"
                                height="16px"
                                objectFit="contain"
                                fallback={<Box width="16px" height="16px" />}
                              />
                            )}
                            <Text fontSize="sm">
                              {link.title || link.url}
                            </Text>
                          </HStack>
                          <Text fontSize="xs" color="blue.500">
                            {link.url}
                          </Text>
                        </Box>
                        <IconButton
                          aria-label="Remove link"
                          icon={<TrashSimple />}
                          size="sm"
                          colorScheme="red"
                          variant="ghost"
                          onClick={() => onRemoveLink(index)}
                        />
                      </HStack>
                    ))}
                    <HStack>
                      <Input
                        placeholder="Title"
                        value={newLinkTitle}
                        onChange={(e) => setNewLinkTitle(e.target.value)}
                      />
                      <Input
                        placeholder="URL"
                        value={newLinkUrl}
                        onChange={(e) => setNewLinkUrl(e.target.value)}
                      />
                      <IconButton
                        aria-label="Add link"
                        icon={<LinkIcon />}
                        colorScheme="blue"
                        onClick={onAddLink}
                      />
                    </HStack>
                  </Stack>
                </Collapse>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"space-between"}>
              {isUpdating ? (
                <Button
                  rightIcon={<TrashSimple />}
                  colorScheme="red"
                  variant="outline"
                  mr={3}
                  isLoading={deleteEntry.isLoading}
                  onClick={onDeleteEntry}
                >
                  Delete
                </Button>
              ) : (
                <Box />
              )}
              <Flex>
                <Button variant="ghost" mr={3} onClick={onPrepareModalClose}>
                  Close
                </Button>
                <Button
                  isLoading={createEntry.isLoading || updateEntry.isLoading}
                  onClick={onModifyEntry}
                  colorScheme="blue"
                >
                  {`${isUpdating ? "Update" : "Create"}`} Entry
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
          {entry?.updatedAt &&
            <ModalFooter opacity={0.6} textColor={"var(--chakra-colors-text)"} justifyContent={"flex-start"}>
              <Text fontSize={"12px"}>
                Last Updated: {format(new Date(entry?.updatedAt), `EEEE do MMMM, yyyy 'at' h:mma`)}
              </Text>
            </ModalFooter>}
        </ModalContent>
      </Modal>
    </>
  );
};
