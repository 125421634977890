import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  MenuButton,
  Avatar,
  Center,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  HStack,
  Tag,
} from "@chakra-ui/react";
import {
  ArrowRight,
  CaretDown,
  CaretRight,
  ChatsCircle,
  Headset,
  List,
  Moon,
  Newspaper,
  SignOut,
  SunDim,
  User,
  X,
} from "phosphor-react";
import IconLight from "../../Assets/icon.png";
import IconDark from "../../Assets/icon_dark.png";
import styles from "./DraftNavbar.module.scss";
import { useQuery } from "react-query";
import { useAuthStore } from "../../Store/AuthStore";
import UsersApi from "../../Api/Resources/Users/UsersApi";
import shallow from "zustand/shallow";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { NavbarItemType } from "./Types/NavbarItemType";
import NotificationButton from "./Components/NotificationButton";
import { Platform, usePlatformStore } from "../../Store/PlatformStore";
import { useMemo } from "react";
import { useSettingsStore } from "../../Store/SettingsStore";
import { useFeatureFlag } from "../../Hooks/useFeatureFlag";
const DraftNavbar = (props: {
  items: NavbarItemType[];
  isLoggedIn: boolean;
}) => {
  const isWhatsNewAnnouncementEnabled = useFeatureFlag("whatsNewAnnouncement", false);

  const { whatsNewAnnouncementId, setWhatsNewAnnouncementId } = useSettingsStore(
    (state) => ({
      whatsNewAnnouncementId: state.whatsNewAnnouncementId,
      setWhatsNewAnnouncementId: state.setWhatsNewAnnouncementId
    }),
    shallow
  );

  const { items, isLoggedIn } = props;
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();

  const { platform } = usePlatformStore(
    (state) => ({ platform: state.platform }),
    shallow
  );

  const isMenubar = useMemo(() => {
    return platform === Platform.Menubar;
  }, []);

  const showDrawer = useMemo(() => {
    return !isMenubar;
  }, [isMenubar]);

  const showNavigation = useMemo(() => {
    return !isMenubar;
  }, [isMenubar]);

  const showLogo = useMemo(() => {
    return !isMenubar;
  }, [isMenubar]);

  const showNotifications = useMemo(() => {
    return !isMenubar;
  }, [isMenubar]);

  const showUser = useMemo(() => {
    return !isMenubar;
  }, [isMenubar]);

  const { clearAuthTokens, authTokens } = useAuthStore(
    (state) => ({
      clearAuthTokens: state.clearAuthTokens,
      authTokens: state.authTokens,
    }),
    shallow
  );

  const logout = () => {
    clearAuthTokens();
  }

  const usersApi = UsersApi();

  const { data: user } = useQuery(["user", authTokens?.userId], () => {
    if (authTokens?.userId) {
      return usersApi.get(authTokens?.userId);
    }
  });

  if (!showDrawer && !showNavigation && !showLogo && !showNotifications) {
    return <Box height={8} />;
  }

  return (
    <Box>
      <Flex
        height={isLoggedIn ? "56px" : "80px"}
        minH={"60px"}
        alignItems={"center"}
        align={"center"}
      >
        {showDrawer && (
          <Flex
            flex={{ md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={isOpen ? <X size={24} /> : <List size={24} />}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
        )}
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          {showLogo && (
            <NavLink to="/">
              <img
                style={{
                  height: isLoggedIn ? "32px" : "42px",
                }}
                className={styles.icon}
                src={colorMode === "light" ? IconLight : IconDark}
              />
            </NavLink>
          )}
          {showNavigation && (
            <Flex
              alignItems={"center"}
              display={{ base: "none", md: "flex" }}
              ml={0}
            >
              <DesktopNav items={items} />
            </Flex>
          )}
        </Flex>

        {isLoggedIn ? (
          <HStack spacing={4}>
            {showNotifications && <NotificationButton />}
            {showUser && (
              <Menu placement="bottom-end">
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar size={"sm"} src={user?.avatarThumbnail} />
                </MenuButton>
                <MenuList alignItems={"center"}>
                  <Center>
                    <Avatar size={"lg"} src={user?.avatarThumbnail} />
                  </Center>
                  <Center marginTop={"12px"} gap={2} alignItems={"center"}>
                    <p>{`${user?.givenName} ${user?.familyName}`}</p>
                    {user?.billingPlan && (
                      <Tag size={"sm"} style={{ textTransform: "uppercase" }}>
                        {user?.billingPlan}
                      </Tag>
                    )}
                  </Center>
                  <MenuDivider />
                  {/* <MenuItem
                    onClick={() => {
                      navigate(`people/${user?.id}`);
                    }}
                    icon={<User size={24} />}
                  >
                    View Profile
                  </MenuItem>
                  <MenuDivider /> */}
                  <MenuItem
                    onClick={() => {
                      navigate("/account");
                    }}
                    icon={<User size={24} />}
                  >
                    Account
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    onClick={toggleColorMode}
                    icon={colorMode === "light" ? <Moon size={24} /> : <SunDim size={24} />}
                  >
                    {colorMode === "light" ? "Dark Theme" : "Light Theme"}
                  </MenuItem>

                  <MenuDivider />
                  <MenuItem
                    onClick={() => navigate("/app-support")}
                    icon={<Headset size={24} />}
                  >
                    Support
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/app-contact")}
                    icon={<ChatsCircle size={24} />}
                  >
                    Feedback
                  </MenuItem>
                  {isWhatsNewAnnouncementEnabled && <MenuItem
                    onClick={() => {
                      if (whatsNewAnnouncementId > 0) {
                        setWhatsNewAnnouncementId(whatsNewAnnouncementId - 1)
                      }
                    }}
                    icon={<Newspaper size={24} />}
                  >
                    What's New
                  </MenuItem>}
                  <MenuDivider />
                  <MenuItem
                    onClick={logout}
                    icon={<SignOut size={24} />}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
        ) : (
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <Button
              as={NavLink}
              fontSize={"sm"}
              fontWeight={500}
              variant={"link"}
              to={"/login"}
            >
              Sign In
            </Button>
            <Button
              rightIcon={<ArrowRight weight="bold" />}
              as={NavLink}
              variant={"outline"}
              display={{ base: "none", md: "inline-flex" }}
              fontSize={"sm"}
              fontWeight={500}
              colorScheme="blue"
              to={"/sign-up"}
            >
              Create an Account
            </Button>
          </Stack>
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav items={items} />
      </Collapse>
    </Box>
  );
};

const DesktopNav = (props: { items: NavbarItemType[] }) => {
  const { items } = props;
  const location = useLocation();

  // Custom isActive state for links
  const activeRoute = useMemo(() => {
    const path = location.pathname;

    if (path.includes("projects")) {
      return "/projects";
    }

    if (path.includes("teams")) {
      return "/teams";
    }

    if (path === "/") {
      return "/";
    }
  }, [location.pathname]);

  return (
    <Stack direction={"row"} spacing={2}>
      {items.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <NavLink
                end={navItem.route === "/"}
                to={navItem.route}
                style={
                  activeRoute === navItem.route
                    ? {
                      backgroundColor: "var(--chakra-colors-yellow200)",
                      color: "var(--chakra-colors-yellow500)",
                    }
                    : undefined
                }
                className={styles.navbarItem}
                children={() => {
                  return navItem.label;
                }}
              />
            </PopoverTrigger>
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, route, subLabel }: NavItem) => {
  return (
    <Link
      href={route}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={CaretRight} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = (props: { items: NavbarItemType[] }) => {
  const { items } = props;

  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {items.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, route }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={NavLink}
        to={route ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={CaretDown}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <NavLink key={child.label} to={child.route ?? ""}>
                {child.label}
              </NavLink>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  route?: string;
}

// const NAV_ITEMS: Array<NavItem> = [
//   {
//     label: "Inspiration",
//     children: [
//       {
//         label: "Explore Design Work",
//         subLabel: "Trending Design to inspire you",
//         href: "#",
//       },
//       {
//         label: "New & Noteworthy",
//         subLabel: "Up-and-coming Designers",
//         href: "#",
//       },
//     ],
//   },
//   {
//     label: "Find Work",
//     children: [
//       {
//         label: "Job Board",
//         subLabel: "Find your dream design job",
//         href: "#",
//       },
//       {
//         label: "Freelance Projects",
//         subLabel: "An exclusive list for contract work",
//         href: "#",
//       },
//     ],
//   },
//   {
//     label: "Learn Design",
//     href: "#",
//   },
//   {
//     label: "Hire Designers",
//     href: "#",
//   },
// ];

export default DraftNavbar;
