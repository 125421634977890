import { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import shallow from "zustand/shallow";
import "./App.scss";
import { useAppStore } from "./Store/AppStore";
import { Platform, usePlatformStore } from "./Store/PlatformStore";
import Announcement from "./UIKit/Announcement/Announcement";
import Footer from "./UIKit/Footer/Footer";
import { Navbar } from "./UIKit/Navigation/Navbar";
import { useQuery } from "react-query";
import useOrganisationsApi from "./Api/Resources/Organisations/OrganisationsApi";
import { useAuthStore, useUserId } from "./Store/AuthStore";
import {
  useFetchPermissions,
} from "./Store/PermissionStore";
import { useAuthInterceptor } from "./Api/useAuthInterceptor";
import { useLDClient } from "launchdarkly-react-client-sdk";

function App({
  showFooter = true,
  isLoggedIn = false,
  showNav = true,
  showPageHeader = true,
}: {
  showNav?: boolean;
  showFooter?: boolean;
  isLoggedIn?: boolean;
  showPageHeader?: boolean;
}) {
  const userId = useUserId();
  const ldClient = useLDClient();
  const location = useLocation();

  // Set up the auth interceptor...
  useAuthInterceptor({ isLoggedIn });

  const organisationsApi = useOrganisationsApi();
  const query = new URLSearchParams(location.search);
  const platform = query.get("platform");

  const { authTokens } = useAuthStore(
    (state) => ({ authTokens: state.authTokens }),
    shallow
  );

  const { pageData } = useAppStore(
    (state) => ({ pageData: state.pageData }),
    shallow
  );

  const { setPlatform } = usePlatformStore(
    (state) => ({ setPlatform: state.setPlatform }),
    shallow
  );

  useEffect(() => {
    if (userId && ldClient) {
      ldClient.identify({
        key: userId,
      });
    }
  }, [userId]);

  // Fetch permissions on app load...
  useFetchPermissions();

  useEffect(() => {
    setPlatform(platform as Platform);
  }, [platform]);

  const { data: _organisations, isFetching: isFetchingOrganisations } =
    useQuery(["organisations", authTokens?.userId], () => {
      if (authTokens?.userId) {
        return organisationsApi.list(authTokens?.userId!);
      }
    });

  const hasOrganisations = useMemo(() => {
    return (_organisations?.length ?? 0) > 0;
  }, [_organisations]);

  const pages = useMemo(() => {
    let items = [
      {
        label: "Timesheet",
        route: "/",
      },
      {
        label: "Projects",
        route: "/projects",
      },
    ];

    if (hasOrganisations) {
      items.push({
        label: "Teams",
        route: "/teams",
      });
    }

    return items;
  }, [hasOrganisations]);

  return (
    <div className="App">
      <Announcement />

      {showNav && (
        <Navbar
          {...pageData}
          isFloating={false}
          isLoggedIn={isLoggedIn}
          showPageHeader={showPageHeader}
          items={isLoggedIn ? pages : []}
        />
      )}
      <Outlet />
      {showFooter && <Footer />}
    </div>
  );
}

export default App;
