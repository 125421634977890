import { UserPermissions } from "../../../Store/PermissionStore";
import useMethods from "../../useMethods";
import { OrganisationInvite } from "../Organisations/OrganisationsApiTypes";
import { User } from "./UsersApiTypes";

const useUsersApi = () => {
  const methods = useMethods();

  const get = async (userId: string): Promise<User> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}`
    );
    return result?.data;
  };

  const list = async (): Promise<OrganisationInvite[]> => {
    const result = await methods.get(`${process.env.REACT_APP_API_URL}/users`);
    return result?.data;
  };

  const listInvites = async (userId: string): Promise<OrganisationInvite[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/invites`
    );
    return result?.data;
  };

  const acceptInvite = async (options: {
    inviteId: string;
    userId: string;
  }): Promise<OrganisationInvite> => {
    const { userId, inviteId } = options;
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/users/${userId}/invites/${inviteId}/accept`,
      {}
    );
    return result?.data;
  };

  const ignoreInvite = async (options: {
    inviteId: string;
    userId: string;
  }): Promise<OrganisationInvite> => {
    const { userId, inviteId } = options;
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/users/${userId}/invites/${inviteId}/ignore`,
      {}
    );
    return result?.data;
  };

  const signIn = async (
    email: string,
    password: string,
    platform: string = "web"
  ): Promise<{
    redirectUrl: string;
    accessToken: string;
    refreshToken: string;
    userId: string;
  }> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/auth/login?platform=${platform}`,
      { email, password }
    );

    return result?.data;
  };

  const signUp = async (options: {
    givenName: string;
    familyName: string;
    email: string;
    password: string;
  }): Promise<{ redirectUrl: string }> => {
    const { password, email, givenName, familyName } = options;

    let myWindow = window as any;

    // Send a custom event
    if (typeof window !== "undefined" && typeof myWindow.gtag === "function") {
      myWindow.gtag("event", "login");
    }
    
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/auth/sign-up`,
      { email, password, givenName, familyName }
    );
    return result?.data;
  };

  const getPermissions = async (userId: string): Promise<UserPermissions> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/permissions`
    );
    return result?.data;
  };

  const requestPasswordReset = async (email: string): Promise<void> => {
    await methods.post(
      `${process.env.REACT_APP_API_URL}/auth/request-reset-password`,
      { email }
    );
  };

  const verifyEmail = async (option: {
    token: string;
    email: string;
  }): Promise<void> => {
    await methods.post(
      `${process.env.REACT_APP_API_URL}/auth/verify-email`,
      option
    );
  };

  const resendVerificationEmail = async (email: string): Promise<void> => {
    await methods.post(
      `${process.env.REACT_APP_API_URL}/auth/resend-verification-email`,
      {
        email,
      }
    );
  };

  const update = async (options: {
    userId: string;
    user: Partial<User>;
  }): Promise<void> => {
    const { userId, user } = options;

    await methods.patch(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
      ...user,
    });
  };

  const resetPassword = async (options: {
    token: string;
    newPassword: string;
  }): Promise<void> => {
    const { token, newPassword } = options;
    await methods.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
      token,
      newPassword,
    });
  };

  const uploadProfilePicture = async (
    file: File,
    userId: string,
    onProgress: (progress: number) => void
  ): Promise<void> => {
    try {
      // Create FormData and append the file
      const formData = new FormData();
      formData.append('file', file);
      methods.upload(`${process.env.REACT_APP_API_URL}/users/${userId}/resources/avatar`, formData, onProgress);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return {
    get,
    signIn,
    signUp,
    listInvites,
    acceptInvite,
    ignoreInvite,
    getPermissions,
    requestPasswordReset,
    resetPassword,
    verifyEmail,
    resendVerificationEmail,
    update,
    uploadProfilePicture
  };
};

export default useUsersApi;
