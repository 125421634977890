import useMethods from "../../useMethods";
import {
  AnalysisSummary,
  GraphData,
  GraphDataRequest as GraphDataResponse,
} from "./AnalysisApiTypes";

const useAnalysisApi = () => {
  const methods = useMethods();

  const getQueryString = (options: {
    startDate?: string;
    endDate?: string;
    projects: string[];
    members: string[];
    categories: string[];
    keys: string[];
    type?: "pdf" | "excel" | "csv";
  }) => {
    const { startDate, endDate } = options;
    const dates =
      startDate && endDate
        ? `startDate=${startDate}&endDate=${endDate}`
        : undefined;
    const categories =
      options.categories.length > 0
        ? `categories=${options.categories.join(",")}`
        : undefined;
    const members =
      options.members.length > 0
        ? `members=${options.members.join(",")}`
        : undefined;
    const projects =
      options.projects.length > 0
        ? `projects=${options.projects.join(",")}`
        : undefined;
    const keys =
      options.keys.length > 0 ? `keys=${options.keys.join(",")}` : undefined;
    const type = 
      options.type ? `type=${options.type}` : undefined

    let queryString = "";
    if (dates || categories || members || projects || keys || type) {
      queryString = `?${[dates, categories, members, projects, keys, type]
        .filter((x) => x)
        .join("&")}`;
    }

    return queryString;
  };

  const exportData = async (options: {
    startDate?: string;
    endDate?: string;
    projects: string[];
    members: string[];
    categories: string[];
    organisationId?: string;
    projectId?: string;
    teamId?: string;
    userId?: string;
    keys: string[];
  }): Promise<any> => {
    const { organisationId, teamId, userId, projectId } = options;
    const queryString = getQueryString(options);

    if (organisationId && teamId) {
      const result = await methods.save(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/export${queryString}`
      );

      return result;
    }

    if (userId) {
      const result = await methods.save(
        `${process.env.REACT_APP_API_URL}/users/${userId}/export${queryString}`
      );

      return result;
    }
  };


  const createInvoice = async (options: {
    startDate?: string;
    endDate?: string;
    projects: string[];
    members: string[];
    categories: string[];
    organisationId?: string;
    projectId?: string;
    teamId?: string;
    userId?: string;
    keys: string[];
    type: "pdf" | "excel" | "csv";
  }): Promise<void> => {
    const { organisationId, teamId, userId, projectId } = options;
    const queryString = getQueryString(options);

    try {
        let result;

        if (organisationId && teamId) {
            result = await methods.get(
                `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/invoice${queryString}`
            );
        } else if (userId) {
            result = await methods.get(
                `${process.env.REACT_APP_API_URL}/users/${userId}/invoice${queryString}`
            );
        }

        if (result?.data?.url) {
            // Open the URL in a new tab instead of trying to download directly
            window.open(result?.data.url, '_blank');
        }
    } catch (error) {
        console.error('Error downloading invoice:', error);
        throw error;
    }
  };

  const summary = async (options: {
    startDate: string;
    userId: string;
  }): Promise<AnalysisSummary> => {
    const { startDate, userId } = options;
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/summary?startDate=${startDate}`
    );

    return result?.data;
  };

  const graph = async (options: {
    startDate?: string;
    endDate?: string;
    userId?: string;
    projectId?: string;
    organisationId?: string;
    teamId?: string;
    projects: string[];
    members: string[];
    categories: string[];
    keys: string[];
  }): Promise<GraphDataResponse> => {
    const queryString = getQueryString(options);
    const { userId, projectId, organisationId, teamId } = options;

    if (organisationId && teamId && projectId) {
      const result = await methods.get(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/graph${queryString}`
      );

      return result?.data;
    }

    if (organisationId && teamId) {
      const result = await methods.get(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/graph${queryString}`
      );

      return result?.data;
    }

    if (projectId && userId) {
      const result = await methods.get(
        `${process.env.REACT_APP_API_URL}/users/${userId}/projects/${projectId}/graph${queryString}`
      );

      return result?.data;
    }

    if (userId) {
      const result = await methods.get(
        `${process.env.REACT_APP_API_URL}/users/${userId}/graph${queryString}`
      );

      return result?.data;
    }

    throw new Error("Organisation ID or User ID is required");
  };

  return {
    createInvoice,
    exportData,
    summary,
    graph,
  };
};

export default useAnalysisApi;
