import { Avatar, AvatarGroup, Flex } from "@chakra-ui/react";
import { CaretRight } from "phosphor-react";
import ResponsiveContainer from "../../../../../UIKit/ResponsiveContainer/ResponsiveContainer";
import styles from "./TeamListItem.module.scss";
import { Item } from "../../../../../UIKit/ListItem/ListItem";
import { Team } from "../../../../../Api/Resources/Teams/TeamsApiTypes";

export const TeamListItem = ({
  item,
  onClick,
}: {
  item: Item;
  onClick?: (link: string) => void;
}) => {
  const team = item.data as Team

  return (
    <Flex
      onClick={() => onClick && item.link && onClick(item.link)}
      className={styles.listItem}
    >
      <ResponsiveContainer>
        <Flex alignItems={"center"}>
          <Flex className={styles.container}>
            <Flex className={styles.content}>
              <Flex className={styles.name}>{item.label}</Flex>
              <Flex className={styles.description}>{item.description}</Flex>
              <Flex marginTop={2}>
                <AvatarGroup size="sm">
                  {team.members?.map((member) => (
                    <Avatar key={member.id} src={member.avatarThumbnail} name={member.displayName} />
                  ))}
                </AvatarGroup>
              </Flex>
            </Flex>
          </Flex>
          <CaretRight weight="bold" size={24} />
        </Flex>
      </ResponsiveContainer>
    </Flex>
  );
};
