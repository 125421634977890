import { format } from "date-fns";
import { Entry, EntryLink } from "./EntriesApiTypes";
import EntriesApi from "./EntriesApi";
import { useMutation, useQueryClient } from "react-query";
import { getSecondsFromTime } from "../../../Utilities/TimeUtility";

const useEntryMutations = () => {
  const entriesApi = EntriesApi();
  const queryClient = useQueryClient();

  const updateEntry = useMutation({
    mutationFn: (options: {
      teamId?: string;
      userId?: string;
      organisationId?: string;
      startDate: Date;
      time: string;
      entry: Entry;
      projectId?: string;
      categoryId?: string;
      description?: string;
      links?: EntryLink[];
    }) => {
      const {
        description,
        time,
        entry,
        projectId,
        categoryId,
        teamId,
        userId,
        organisationId,
        links,
        startDate
      } = options;

      const { project, category, links: _, ...remaining } = entry;

      return entriesApi.update({
        organisationId,
        userId,
        teamId,
        body: {
          ...remaining,
          date: format(startDate, "yyyy-MM-dd"),
          projectId: projectId ?? null,
          categoryId: categoryId ?? null,
          description,
          time: getSecondsFromTime(time),
          links: links ?? [],
        },
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["entries"] });
      queryClient.invalidateQueries({ queryKey: ["metadata"] });
      queryClient.invalidateQueries({ queryKey: ["entries"] });
    },
  });

  const approveEntry = useMutation({
    mutationFn: (options: {
      approved: boolean;
      entry: Entry;
      projectId: string;
      organisationId: string;
      teamId: string;
    }) => {
      const { entry, approved, teamId, organisationId } = options;

      return entriesApi.approve({
        teamId,
        organisationId,
        body: {
          entryId: entry.id!,
          approved,
        },
      });
    },
    onSuccess: (_, variables) => {
      const { organisationId, projectId } = variables;

      queryClient.invalidateQueries({
        queryKey: ["awaitingApprovalEntries", projectId, organisationId],
      });
    },
  });

  const createEntry = useMutation({
    mutationFn: (options: {
      organisationId?: string;
      teamId?: string;
      userId?: string;
      startDate: Date;
      time: string;
      order: number;
      description?: string;
      projectId?: string;
      categoryId?: string;
      links?: EntryLink[];
    }) => {
      const {
        description,
        startDate,
        time,
        projectId,
        categoryId,
        order,
        organisationId,
        teamId,
        userId,
        links,
      } = options;

      return entriesApi.create({
        organisationId,
        teamId,
        userId,
        projectId,
        body: {
          projectId,
          categoryId,
          description,
          date: format(startDate, "yyyy-MM-dd"),
          time: getSecondsFromTime(time),
          order,
          links,
        },
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["entries"] });
      queryClient.invalidateQueries({ queryKey: ["metadata"] });
    },
  });

  const deleteEntry = useMutation({
    mutationFn: (options: {
      entryId: string;
      organisationId?: string;
      teamId?: string;
      userId?: string;
    }) => {
      return entriesApi.deleteOne(options);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["entries"] });
      queryClient.invalidateQueries({ queryKey: ["metadata"] });
    },
  });

  return {
    createEntry,
    updateEntry,
    deleteEntry,
    approveEntry,
  };
};

export default useEntryMutations;
