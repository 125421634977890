import { Flex } from "@chakra-ui/react"
import { Sparkle } from "phosphor-react"
import { Card, CardHeader } from "../../../../UIKit/Card/Card"
import styles from "./AIEntryCard.module.scss"
import { useEffect } from "react"
import { useUserId } from "../../../../Store/AuthStore"
import useAiApi from "../../../../Api/Resources/Ai/AiApi"
import { useQuery } from "react-query"
import CollapseExpand from "../../../../Components/Collapse/CollapseExpand"

export const AIEntryCard = ({ className, isOpen, selectedDate }: { className: string, isOpen: boolean, selectedDate: Date }) => {
    const { summarizeEntries } = useAiApi();
    const userId = useUserId();

    const { data: fetchedSummary, isFetching: isFetchingSummary } = useQuery({
        queryKey: ["summarizeEntries", selectedDate],
        queryFn: () => {
            return summarizeEntries({
                userId,
                date: selectedDate,
            });
        },
        enabled: isOpen
    });

    useEffect(() => {
        if (isOpen && userId && selectedDate) {
            summarizeEntries({
                userId,
                date: selectedDate
            })
        }
    }, [isOpen, userId, selectedDate])

    if (fetchedSummary?.status === "failed") {
        return null;
    }

    return (
        <CollapseExpand isOpen={isOpen} className={`${styles.summaryCard}`}>
            <div className={className}>
                <Card isPadded={false}>
                    <CardHeader title={<Flex alignItems={"center"} gap={2}><Sparkle size={20} />AI Summary</Flex>} />
                    <Flex className={styles.summaryText} direction="column" gap={2}>
                        {isFetchingSummary ? (
                            <>
                                <div className={styles.shimmer} style={{ width: '100%', height: '26px' }}></div>
                                <div className={styles.shimmer} style={{ width: '90%', height: '26px' }}></div>
                                <div className={styles.shimmer} style={{ width: '95%', height: '26px' }}></div>
                            </>
                        ) : (
                            fetchedSummary?.summary
                        )}
                    </Flex>
                </Card>
            </div>
        </CollapseExpand>
    )
}