import {
  Avatar,
  AvatarGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  SimpleGrid,
  Spinner,
  Stat,
  StatHelpText,
  StatNumber,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import {
  ChartPie,
  CheckCircle,
  CircleWavy,
  Export,
  File,
  FilePlus,
  Placeholder,
} from "phosphor-react";
import { RangeDatePicker } from "../../../../UIKit/DatePicker/RangeDatePicker";
import CustomTable, { ColumnType, TableRow } from "../../../../UIKit/Table/Table";
import { format, parseISO, startOfDay } from "date-fns";
import { formatTime } from "../../../../Utilities/TimeUtility";
import DoughnutChart from "../../../../UIKit/Graphs/DoghnutChart";
import { useMemo, useState } from "react";
import useAnalysisApi from "../../../../Api/Resources/Analysis/AnalysisApi";
import { MultiValue, PropsValue } from "react-select";
import { useParams } from "react-router-dom";
import useCategoriesApi from "../../../../Api/Resources/Categories/CategoriesApi";
import useProjectsApi from "../../../../Api/Resources/Projects/ProjectsApi";
import useEntriesApi from "../../../../Api/Resources/Entries/EntriesApi";
import { useQuery } from "react-query";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";
import { useUserId } from "../../../../Store/AuthStore";
import { useCurrency } from "../../../../Hooks/useCurrency";
import BurndownChart from "../../../../UIKit/Graphs/BurndownChart";
import { EntryLink } from "../../../../Components/EntryLink";
import { CategoryLink } from "../../../../Components/CategoryLink";

export interface OverviewTabProps {
  hasTeam?: boolean;
  subtitle?: string;
  selectedDates?: Date[];
  controls?: JSX.Element[] | JSX.Element;
}

const OverviewTab = (props: OverviewTabProps) => {
  const { hasTeam = false } = props;
  const userId = useUserId();
  const currency = useCurrency();
  const [selectedDates, setSelectedDates] = useState<Date[]>();
  const [selectedFilterCategory, setSelectedFilterCategory] =
    useState<MultiValue<{ value: string; label: string }> | null>();
  const [selectedFilterMember, setSelectedFilterMember] = useState<MultiValue<{
    value: string;
    label: string;
  }> | null>();
  const { projectId, teamId, organisationId } = useParams();
  const segments = [
    { label: "Table", value: "table" },
    { label: "Graph", value: "graph" },
  ];

  const [selectedApproval, setSelectedApproval] =
    useState<PropsValue<{ label: string; value: string }>>(null);

  const [selectedSegment, setSelectedSegment] = useState<string>(
    segments[0].value
  );

  const { data: project } = useQuery(
    ["project", projectId],
    () => {
      if (projectId) {
        return projectsApi.get({
          userId,
          projectId,
          teamId,
          organisationId,
        });
      }

      return;
    }
  );

  const analysisApi = useAnalysisApi();
  const categoriesApi = useCategoriesApi();
  const projectsApi = useProjectsApi();
  const entriesApi = useEntriesApi();

  const onCreateInvoice = (type: "pdf" | "excel" | "csv") => {
    let startDate: string | undefined;
    let endDate: string | undefined;

    if (selectedDates?.length === 2) {
      startDate = format(startOfDay(selectedDates[0]), "yyyy-MM-dd");
      endDate = format(startOfDay(selectedDates[1]), "yyyy-MM-dd");
    }

    return analysisApi.createInvoice({
      startDate,
      endDate,
      categories:
        selectedFilterCategory?.map((category) => category.value) ?? [],
      projects: [projectId!],
      members: selectedFilterMember?.map((member) => member.value) ?? [],
      keys: [],
      organisationId,
      teamId,
      userId,
      projectId,
      type,
    });
  };

  const { data: entries, isFetching: isFetchingEntries } = useQuery(
    [
      "entries",
      projectId,
      organisationId,
      selectedDates,
      selectedFilterCategory,
      selectedFilterMember,
    ],
    () => {
      if (projectId) {
        let startDate: string | undefined;
        let endDate: string | undefined;

        if (selectedDates?.length === 2) {
          startDate = format(startOfDay(selectedDates[0]), "yyyy-MM-dd");
          endDate = format(startOfDay(selectedDates[1]), "yyyy-MM-dd");
        }

        return entriesApi.listForProject({
          userId,
          organisationId,
          teamId,
          projectId,
          startDate,
          endDate,
          categories:
            selectedFilterCategory?.map((category) => category.value) ?? [],
          members: selectedFilterMember?.map((member) => member.value) ?? [],
        });
      }

      return;
    }
  );

  const { data: graphData } = useQuery(
    [
      "graphEntries",
      projectId,
      organisationId,
      selectedDates,
      selectedFilterCategory,
      selectedFilterMember,
    ],
    () => {
      if (projectId) {
        let startDate: string | undefined;
        let endDate: string | undefined;

        if (selectedDates?.length === 2) {
          startDate = format(startOfDay(selectedDates[0]), "yyyy-MM-dd");
          endDate = format(startOfDay(selectedDates[1]), "yyyy-MM-dd");
        }

        return analysisApi.graph({
          projectId,
          organisationId,
          teamId,
          userId,
          projects: [projectId],
          startDate,
          endDate,
          categories:
            selectedFilterCategory?.map((category) => category.value) ?? [],
          members: selectedFilterMember?.map((member) => member.value) ?? [],
          keys: [
            "monthly_spend",
            "categories_comparison",
            "billable_hours_comparison",
            "total_project_spend",
          ],
        });
      }

      return;
    }
  );

  const loadCategories = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    categoriesApi
      .list({
        projectId: projectId!,
        organisationId,
        teamId,
        userId,
        search: inputValue,
      })
      .then((categories) => {
        const result = categories.map((category) => {
          return {
            label: category.name ?? "Untitled Category",
            value: category.id!,
          };
        });

        callback(result);
      });
  };

  const loadMembers = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    projectsApi
      .listUsers({
        projectId: projectId!,
        organisationId,
        teamId,
        search: inputValue,
      })
      .then((users) => {
        const result = users?.map((user) => {
          return {
            label: user.displayName ?? "Unknown User",
            value: user.id!,
          };
        });

        callback(result);
      });
  };


  const rows: TableRow[] = useMemo(() => {
    return (
      entries?.data?.map((entry) => {
        return {
          description: entry.description,
          category: entry.category?.name,
          date: format(parseISO(entry.date), "yyyy-MM-dd"),
          time: formatTime(entry.time),
          users: (
            <AvatarGroup size="sm" max={2}>
              {entry?.users?.map((user) => {
                return <Avatar name={user.displayName} />;
              })}
            </AvatarGroup>
          ),
          approved: entry.approved ? <CheckCircle /> : <CircleWavy />,
        };
      }) ?? []
    );
  }, [entries]);

  const showApprovals = hasTeam && project?.requireApproval;

  let tableColumns = hasTeam
    ? [
      { label: "Description", accessor: "description" },
      { label: "Category", accessor: "category" },
      { label: "Date", accessor: "date" },
      { label: "Time", accessor: "time" },
      { label: "Users", accessor: "users" },
    ]
    : [
      { label: "Description", accessor: "description" },
      { label: "Category", accessor: "category", type: ColumnType.CATEGORY },
      { label: "Date", accessor: "date" },
      { label: "Time", accessor: "time" },
    ];

  if (showApprovals) {
    tableColumns = [
      ...tableColumns,
      { label: "Approved", accessor: "approved" },
    ];
  }

  return (
    <VStack paddingBottom={36} spacing={4}>
      <Card isPadded={false}>
        <CardHeader
          title="Entries"
          subtitle={props.subtitle}
          action={
            <HStack>
              <Menu>
                <MenuButton
                  as={Button}
                  leftIcon={<Export weight="bold" />}
                  variant="outline"
                  colorScheme="blue"
                >
                  Export
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => onCreateInvoice("csv")}>CSV</MenuItem>
                  <MenuItem onClick={() => onCreateInvoice("pdf")}>PDF</MenuItem>
                  <MenuItem onClick={() => onCreateInvoice("excel")}>Excel</MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          }
        />
        <HStack spacing={4} justifyContent={"space-evenly"}>
          <FormControl>
            <FormLabel htmlFor="dateRange">Date Range</FormLabel>
            <RangeDatePicker
              name="dateRange"
              usePortal
              selectedDates={selectedDates}
              onDateChange={setSelectedDates}
            />
          </FormControl>
          <Flex width={"100%"}>
            <FormControl>
              <FormLabel htmlFor="dateRange">Categories</FormLabel>
              <AsyncSelect
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    borderColor: "var(--chakra-colors-chakra-border-color)",
                    backgroundColor: "var(--chakra-colors-background100)",
                  }),
                }}
                isClearable
                isMulti
                placeholder="Select Categories"
                value={selectedFilterCategory}
                onChange={(value) => {
                  if (value.length === 0) {
                    setSelectedFilterCategory(null);
                  }

                  setSelectedFilterCategory(value);
                }}
                loadOptions={loadCategories}
                defaultOptions={true}
              />
            </FormControl>
          </Flex>
          <Flex width={"100%"}>
            {hasTeam && (
              <FormControl>
                <FormLabel htmlFor="dateRange">Members</FormLabel>

                <AsyncSelect
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: "var(--chakra-colors-chakra-border-color)",
                      backgroundColor: "var(--chakra-colors-background100)",
                    }),
                  }}
                  isClearable
                  isMulti
                  placeholder="Select Members"
                  value={selectedFilterMember}
                  onChange={(value) => {
                    if (value.length === 0) {
                      setSelectedFilterMember(null);
                    }

                    setSelectedFilterMember(value);
                  }}
                  loadOptions={loadMembers}
                  defaultOptions={true}
                />
              </FormControl>
            )}
          </Flex>
          {showApprovals && (
            <Flex width={"100%"}>
              <FormControl>
                <FormLabel htmlFor="dateRange">Approval</FormLabel>

                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: "var(--chakra-colors-chakra-border-color)",
                      backgroundColor: "var(--chakra-colors-background100)",
                    }),
                  }}
                  options={[
                    {
                      label: "Approved",
                      value: "approved",
                    },
                    {
                      label: "Unapproved",
                      value: "unapproved",
                    },
                  ]}
                  isClearable={true}
                  placeholder="Select Approval"
                  value={selectedApproval}
                  onChange={(value) => {
                    if (!value) {
                      setSelectedApproval(null);
                    }

                    setSelectedApproval(value);
                  }}
                />
              </FormControl>
            </Flex>
          )}
        </HStack>
      </Card>
      <Card>
        {isFetchingEntries ? (
          <Spinner />
        ) : (
          <CustomTable
            placeholder={{
              label: "No entries",
              icon: Placeholder,
            }}
            renderRow={(options) => {
              const { column } = options;

              switch (column.type) {
                default: {
                  return <StandardTableRow {...options} />;
                }
              }

            }}
            rows={rows}
            columns={tableColumns}
          />
        )}
      </Card>

      <SimpleGrid gap={4} width={"full"} columns={[3]}>
        <Card width={"100%"}>
          <CardHeader
            title="Time Spent on Categories"
            subtitle="View distribution of time spent across categories."
          />

          {isFetchingEntries ? (
            <Spinner />
          ) : graphData && (entries?.count ?? 0) > 0 ? (
            <Flex height={"200px"} padding={4}>
              <DoughnutChart {...graphData["categories_comparison"]} />
            </Flex>
          ) : (
            <Flex
              opacity={0.2}
              flexDirection={"column"}
              alignItems={"center"}
              width={"full"}
              height="full"
              justifyContent={"center"}
            >
              <ChartPie weight="thin" size={180} />
              <Text fontWeight={"500"} fontSize={"xl"}>
                No comparison
              </Text>
            </Flex>
          )}
        </Card>
        <Card>
          <CardHeader
            title="Billable Hours"
            subtitle="A comparison of billable and non-billable hours on this project"
          />

          {isFetchingEntries ? (
            <Spinner />
          ) : graphData && (entries?.count ?? 0) > 0 ? (
            <Flex height={"200px"} padding={4}>
              <DoughnutChart {...graphData["billable_hours_comparison"]} />
            </Flex>
          ) : (
            <Flex
              opacity={0.2}
              flexDirection={"column"}
              alignItems={"center"}
              width={"full"}
              height="full"
              justifyContent={"center"}
            >
              <ChartPie weight="thin" size={180} />
              <Text fontWeight={"500"} fontSize={"xl"}>
                No billable hours
              </Text>
            </Flex>
          )}
        </Card>
        {project?.budget && (
          <Card>
            <CardHeader
              title="Project Spend"
              subtitle="The budget and total spend on this project."
            />
            <Stat>
              <StatNumber fontWeight={600} fontSize={"5xl"}>
                {currency.symbol}
                {(graphData ?? {})["total_project_spend"]?.data?.total ?? 0}
              </StatNumber>
              <StatHelpText fontSize={"3xl"}>
                of {currency.symbol}
                {project?.budget ?? 0}
              </StatHelpText>
            </Stat>
          </Card>
        )}
      </SimpleGrid>
      {project?.budget && graphData && <Card>
        <CardHeader
          title="Budget Burndown"
          subtitle="View the remaining project budget over time."
        />
        <BurndownChart {...graphData["monthly_spend"]} />
      </Card>}
    </VStack>
  );
};

export default OverviewTab;
