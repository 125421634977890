import {
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Image,
  Icon,
  IconProps,
  useColorModeValue,
  useColorMode,
  Button,
} from "@chakra-ui/react";
import ResponsiveContainer from "../../UIKit/ResponsiveContainer/ResponsiveContainer";
import Hero from "../../Assets/stack.png";
import HeroDark from "../../Assets/stack-dark.png";
import SplitWithImage from "../../UIKit/SplitWithImage/SplitWithImage";
import Footer from "../../UIKit/Footer/Footer";
import styles from "./HomeLanding.module.scss";
import TimesheetImage from "../../Assets/timesheet.jpg";
import TimesheetDarkImage from "../../Assets/timesheet_dark.jpg";
import ProjectsImage from "../../Assets/projects.jpg";
import ProjectsDarkImage from "../../Assets/projects_dark.jpg";
import AnalysisImage from "../../Assets/analysis.jpg";
import AnalysisDarkImage from "../../Assets/analysis_dark.jpg";
import ExportImage from "../../Assets/export.jpg";
import ExportImageDark from "../../Assets/export_dark.jpg";
import SyncImage from "../../Assets/sync.jpg";
import SyncDarkImage from "../../Assets/sync_dark.jpg";
import TeamImage from "../../Assets/team.jpg";
import TeamDarkImage from "../../Assets/team_dark.jpg";
import { useNavigate } from "react-router-dom";
import { AppleLogo } from "phosphor-react";

const HomeLanding = () => {
  return <CallToActionWithVideo />;
};

const CallToActionWithVideo = () => {
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      <Box
        className={styles.hero}
        paddingBottom={"0"}
        borderBottom={"1px solid var(--chakra-colors-border100)"}
        paddingTop={{ base: "24px", md: "96px" }}
        bgGradient={useColorModeValue(
          "linear(to-b, white, gray.100)",
          "linear(to-b, gray.800, gray.900)"
        )}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <ResponsiveContainer>
          <Stack
            align={"center"}
            spacing={{ base: 8, md: 10 }}
            direction={{ base: "column", md: "row" }}
          >
            <Stack
              width={"full"}
              alignItems={"center"}
              textAlign={"center"}
            >
              <Box maxWidth={"700px"}>
                <Heading
                  lineHeight={1.3}
                  fontWeight={600}
                  fontSize={{ base: "4xl", sm: "4xl", lg: "6xl" }}
                  marginBottom={10}
                  marginTop={100}
                >
                  Effortless time tracking and invoicing.
                </Heading>
              </Box>
              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={{ base: "column", sm: "row" }}
                justify="center"
              >
                <Button
                  size="lg"
                  colorScheme="blue"
                  fontWeight="bold"
                  px={8}
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                >
                  Get Started for Free
                </Button>
                <a target="_" href="https://apps.apple.com/gb/app/daybreak-time-tracking/id1558197906">
                  <Button
                    size="lg"
                    variant="outline"
                    colorScheme="blue"
                    px={8}
                  >
                    Download macOS App
                  </Button>
                </a>
              </Stack>

              <Image
                paddingTop={"60px"}
                alt={"Hero Image"}
                fit={"contain"}
                align={"center"}
                w={"100%"}
                src={colorMode === "light" ? Hero : HeroDark}
              />
            </Stack>
            <Flex
              flex={1}
              justify={"center"}
              align={"center"}
              position={"relative"}
              w={"full"}
            >
              <Blob
                w={"150%"}
                h={"150%"}
                position={"absolute"}
                top={"-20%"}
                left={0}
                zIndex={-1}
                color={useColorModeValue("red.50", "red.400")}
              />
              <Flex
                alignItems={"flex-end"}
                position={"relative"}
                width={"full"}
                justifyContent={"flex-end"}
              >
                {/* <IconButton
              aria-label={"Play Button"}
              variant={"ghost"}
              _hover={{ bg: "transparent" }}
              icon={<Play size={24} />}
              size={"lg"}
              color={"white"}
              position={"absolute"}
              left={"50%"}
              top={"50%"}
              transform={"translateX(-50%) translateY(-50%)"}
            /> */}
                {/* <Image
                  alt={"Hero Image"}
                  fit={"contain"}
                  align={"center"}
                  w={"80%"}
                  src={Hero}
                /> */}
              </Flex>
            </Flex>
          </Stack>
        </ResponsiveContainer>
      </Box>
      <ResponsiveContainer paddingTop={4} paddingBottom={4}>
        <SplitWithImage
          image={colorMode === "light" ? TimesheetImage : TimesheetDarkImage}
          caption={
            <div>
              The timesheet provides an overview of your tracked time for the week.
              It features a helpful daily totals bar and detailed entries below, including project tags and durations.
              <br /><br />
              You can quickly add or edit entries, ensuring accurate and efficient timesheets.
            </div>
          }
          title={"Timesheet"}
        />
        <SplitWithImage
          image={colorMode === "light" ? ProjectsImage : ProjectsDarkImage}
          caption={
            <div>
              Projects allows you to organize and analyze your work effectively. Within each project, you can review entries filtered by date range and category, making it easy to track progress and export data.
              <br /><br />
              The Categories tab lets you manage task types, ensuring clear differentiation between billable and non-billable activities for better financial management.
            </div>
          }
          title={"Projects & Categories"}
          align="right"
        />
        <SplitWithImage
          image={colorMode === "light" ? AnalysisImage : AnalysisDarkImage}
          caption={
            <div>
              Analyze your tracked time over any period with Daybreak's in-built reporting. You can view data by project, category, or tag, and export it to CSV for further analysis.
              <br /><br />
              A series of graphs help to visualize your data such as a budget burndown, making it easy to identify trends and patterns.
            </div>
          }
          title={"Analysis"}
        />
        <SplitWithImage
          image={colorMode === "light" ? ExportImage : ExportImageDark}
          caption={
            <div>
              Easily create an export of your project's data over any time period to CSV for further analysis or to share with clients.
            </div>
          }
          title={"Export"}
          align="right"
        />
        {/* <SplitWithImage
          image={colorMode === "light" ? SyncImage : SyncDarkImage}
          caption={
            <div>
              Your data is securely stored and synced to the cloud, ensuring you can access it from any device.
              <br /><br />
              Daybreak is available on macOS, and the web (with more platforms coming soon), so you can track your time wherever you are.
            </div>
          }
          title={"Sync"}
        /> */}
        {/* <SplitWithImage
          image={colorMode === "light" ? TeamImage : TeamDarkImage}
          caption={
            <div>
              Invite your team members to collaborate on projects and track time together.
              <br /><br />
              With Daybreak's team management features, you can assign roles and permissions, ensuring everyone has the access they need.
              <br /><br />
              Enhanced team features include timesheet approvals and team reporting.
              <br /><br />
              <Text opacity={0.5} fontSize={"14px"}>*Team features are in beta</Text>
            </div>
          }
          title={"Team Management"}
          align="right"
        /> */}
      </ResponsiveContainer>
      {/* <Pricing /> */}

      <Footer />
    </>
  );
};

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default HomeLanding;
