import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Hide,
  useColorMode,
  HStack,
  Alert,
  AlertIcon,
  Collapse,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Text,
  Center,
} from "@chakra-ui/react";
import BannerImage from "../../Assets/background.jpg";
import { ArrowLeft, GoogleLogo, SquaresFour } from "phosphor-react";
import { useEffect, useState } from "react";
import { useLocation, Navigate, NavLink } from "react-router-dom";
import shallow from "zustand/shallow";
import { useAuthStore } from "../../Store/AuthStore";
import IconLight from "../../Assets/icon.png";
import IconDark from "../../Assets/icon_dark.png";
import useUsersApi from "../../Api/Resources/Users/UsersApi";
import Separator from "../../UIKit/Separator/Separator";
import { Platform } from "../../Store/PlatformStore";

export const LoginPage = ({ isSignUp }: { isSignUp?: boolean }) => {
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
  const [isSigningUp, setIsSigningUp] = useState<boolean>(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const accessToken = query.get("accessToken");
  const refreshToken = query.get("refreshToken");
  const platform = query.get("platform") ?? "web";
  const userId = query.get("userId");
  const usersApi = useUsersApi();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const [showVerificationMessage, setShowVerificationMessage] =
    useState<boolean>(false);
  const [resetEmail, setResetEmail] = useState<string>("");
  const [resetMessage, setResetMessage] = useState<string>("");
  const isDesktopApp = (window as any).electron?.isDesktopApp || false;

  const { setAuthTokens } = useAuthStore(
    (state) => ({ setAuthTokens: state.setAuthTokens }),
    shallow
  );

  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (accessToken && refreshToken && userId) {
      if (platform === Platform.Mac) {
        window.location.href = `daybreak://auth?accessToken=${accessToken}&refreshToken=${refreshToken}&userId=${userId}`;
      } else {
        setAuthTokens({ accessToken, refreshToken, userId });
      }
    }
  }, [refreshToken, accessToken, userId]);

  const signUp = async () => {
    setIsSigningUp(true);

    // Sign up user...
    if (email && password && firstName && lastName) {
      try {
        await usersApi.signUp({
          email,
          password,
          givenName: firstName,
          familyName: lastName,
        });
      } catch (error) {
        console.error(error);
      }

      setIsSigningUp(false);
      setShowVerificationMessage(true);
    } else {
      setShowVerificationMessage(false);
      setIsSigningUp(false);
      throw new Error("Details missing.");
    }
  };

  const signIn = async () => {
    setIsSigningIn(true);

    // Sign in user...
    if (email && password) {
      try {
        const data = await usersApi.signIn(email, password, platform);
        const { accessToken, refreshToken, userId } = data;

        if (platform === Platform.Mac) {
          window.location.href = `daybreak-desktop://auth?accessToken=${accessToken}&refreshToken=${refreshToken}&userId=${userId}`;
        } else if (platform === Platform.Ios) {
          window.location.href = `daybreak-mobile://auth?accessToken=${data.accessToken}&refreshToken=${data.refreshToken}&userId=${data.userId}`;
        }

        return setRedirectUrl(data.redirectUrl);
      } catch (error) {
        console.error(error);
      }

      setIsSigningIn(false);
    } else {
      setIsSigningIn(false);
      throw new Error("No email or password provided.");
    }
  };

  const handlePasswordReset = async () => {
    // Call API to send password reset email
    try {
      await usersApi.requestPasswordReset(resetEmail);
      setResetMessage("Password reset email sent. Please check your inbox.");
    } catch (error) {
      setResetMessage("Failed to send password reset email.");
    }
  };

  if (redirectUrl) {
    if (platform !== Platform.Mac && platform !== Platform.Ios) {
      return <Navigate relative={"route"} to={redirectUrl} replace />;
    } else {
      return (
        <Center w="100%" h="100vh" bg="gray.50">
          <Box
            p={6}
            bg="blue.500"
            color="white"
            borderRadius="md"
            boxShadow="md"
            textAlign="center"
            maxW="md"
          >
            <Text fontSize="xl" fontWeight="bold">
              You've been redirected back to Daybreak for {platform === Platform.Mac ? "Mac" : "iOS"}.
            </Text>
            <Text mt={2}>
              If this was an error, please try again or contact support.
            </Text>
          </Box>
        </Center>
      );
    }
  }

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <Flex
        marginTop={"-42px"}
        p={8}
        flex={1}
        align={"center"}
        justify={"center"}
      >
        <Stack spacing={4} w={"full"} maxW={"lg"}>
          <Flex marginBottom={"42px"} justifyContent={"space-between"}>
            {isDesktopApp ? <></> :
              <Button
                to={"/"}
                as={NavLink}
                variant={"link"}
                width={"fit-content"}
                leftIcon={<ArrowLeft />}
              >
                Back Home
              </Button>}
            <Flex alignItems={"center"}>
              {isSignUp ? `Already` : `Not`} a member?
              <Button
                as={NavLink}
                to={isSignUp ? "/login" : "/sign-up"}
                color={"orange"}
                marginLeft={"8px"}
                variant={"link"}
                width={"fit-content"}
              >
                {isSignUp ? `Sign in` : `Sign up`}
              </Button>
            </Flex>
          </Flex>

          <Heading marginBottom={"24px"} fontSize={"2xl"}>
            <img
              src={colorMode === "light" ? IconLight : IconDark}
              style={{ height: "42px", marginBottom: "16px" }}
            />
          </Heading>
          {isSignUp && (
            <HStack spacing={4}>
              <FormControl id="firstName">
                <FormLabel>First Name</FormLabel>
                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="firstName"
                />
              </FormControl>
              <FormControl id="lastName">
                <FormLabel>Last Name</FormLabel>
                <Input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="lastName"
                />
              </FormControl>
            </HStack>
          )}
          <FormControl id="email">
            <FormLabel>Email address</FormLabel>
            <Input
              placeholder="your@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </FormControl>
          <Stack spacing={6}>
            {!isSignUp && (
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Box />
                <Link color={"blue.500"} onClick={onOpen}>
                  Forgot password?
                </Link>
              </Stack>
            )}
            <Button
              isLoading={isSigningUp || isSigningIn}
              onClick={() => (isSignUp ? signUp() : signIn())}
              colorScheme={"blue"}
              variant={"solid"}
            >
              Sign {isSignUp ? "up" : "in"}
            </Button>

            <Collapse in={showVerificationMessage} animateOpacity>
              <Alert status="success">
                <AlertIcon />
                Please check your email to verify your account before signing
                in.
              </Alert>
            </Collapse>

            <Separator text={"or"} />

            <a
              target={`${isDesktopApp ? "_blank" : ""}`}
              href={`${process.env.REACT_APP_API_URL}/auth/google?platform=${platform}`}
            >
              <Button
                width={"100%"}
                leftIcon={<GoogleLogo size={18} weight="bold" />}
                variant="outline"
              >
                Sign {isSignUp ? "up" : "in"} with Google
              </Button>
            </a>

            <a
              style={{ marginTop: "8px" }}
              target={`${isDesktopApp ? "_blank" : ""}`}
              href={`${process.env.REACT_APP_API_URL}/auth/microsoft?platform=${platform}`}
            >
              <Button
                width={"100%"}
                leftIcon={<SquaresFour size={18} weight="fill" />}
                variant="outline"
              >
                Sign {isSignUp ? "up" : "in"} with Microsoft
              </Button>
            </a>
          </Stack>
        </Stack>
      </Flex>
      <Hide below="md">
        <Flex position={"relative"} flex={1}>
          <Image alt={"Login Image"} objectFit={"cover"} src={BannerImage} />
        </Flex>{" "}
      </Hide>

      {/* Forgot Password Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="resetEmail">
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </FormControl>
            {resetMessage && (
              <Alert status="info" mt={4}>
                <AlertIcon />
                {resetMessage}
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handlePasswordReset}>
              Send Reset Email
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default LoginPage;
