import { Box, Flex, Heading, Image, List, ListItem, Text, VStack } from "@chakra-ui/react";
import RoadIntoTheDistance from "../../Assets/WhatsNew/road-into-the-distance.svg"

const WhatsNewView = () => {
    return (
        <Flex padding={8} gap={8} alignItems="center">
            <Box>
                <Image
                    src={RoadIntoTheDistance}
                    alt="What's new illustration"
                    maxWidth="400px"
                />
            </Box>

            <VStack flex={1} alignItems="flex-start" spacing={6}>
                <Box>
                    <Heading size="lg">What's New</Heading>
                    <Text color="gray.500">Version 1.2.0 • March 2025</Text>
                </Box>

                <List spacing={4}>
                    <ListItem>
                        <Text fontWeight="bold">✨ New Team Management Features</Text>
                        <Text>Improved team collaboration with enhanced member management and permissions</Text>
                    </ListItem>

                    <ListItem>
                        <Text fontWeight="bold">🚀 Performance Improvements</Text>
                        <Text>Faster loading times and smoother navigation throughout the app</Text>
                    </ListItem>

                    <ListItem>
                        <Text fontWeight="bold">🎨 Updated User Interface</Text>
                        <Text>Refreshed design with better accessibility and user experience</Text>
                    </ListItem>

                    <ListItem>
                        <Text fontWeight="bold">🐛 Bug Fixes</Text>
                        <Text>Various bug fixes and stability improvements</Text>
                    </ListItem>
                </List>
            </VStack>
        </Flex>
    );
};

export default WhatsNewView;
