import { formatISO } from "date-fns";
import useMethods from "../../useMethods";
import { AnalyzeEntriesProps } from "./AiApiTypes";

const useAiApi = () => {
    const methods = useMethods();

    const summarizeEntries = async ({
        userId, date
    }: AnalyzeEntriesProps): Promise<{
        summary: string,
        status: string
    }> => {
        const result = await methods.get(
            `${process.env.REACT_APP_API_URL}/users/${userId}/summarize-entries?date=${formatISO(date)}`,
        );

        return result?.data;
    };

    return {
        summarizeEntries
    };
};

export default useAiApi;
