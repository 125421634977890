import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { TrashSimple } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import shallow from "zustand/shallow";
import useOrganisationsApi from "../../../../../Api/Resources/Organisations/OrganisationsApi";
import { Team } from "../../../../../Api/Resources/Teams/TeamsApiTypes";
import useTeamsMutation from "../../../../../Api/Resources/Teams/TeamsMutation";
import { useAuthStore } from "../../../../../Store/AuthStore";
import { TextInput } from "../../../../../UIKit/TextInput/TextInput";
import { usePermissions } from "../../../../../Store/PermissionStore";
import { customSelectStyles } from "../../../../../Theme";

export const ModifyTeamModal = ({
  isOpen,
  onClose,
  organisationId,
  team,
}: {
  isOpen: boolean;
  onClose: () => void;
  organisationId?: string;
  team?: Team;
}) => {
  const { getPermission, getPermissionMessage } = usePermissions();
  const navigate = useNavigate();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string | undefined>();
  const organisationsApi = useOrganisationsApi();

  const { updateTeam, createTeam, deleteTeam } = useTeamsMutation();

  const { authTokens } = useAuthStore(
    (state) => ({ authTokens: state.authTokens }),
    shallow
  );

  useEffect(() => {
    if (team) {
      setName(team.name);
      setDescription(team.description);
    }
  }, [team, isOpen]);

  const isUpdating = useMemo(() => {
    return team !== undefined;
  }, [team]);

  const onModifyTeam = async () => {
    const teamId = team?.id;

    if (name !== undefined && name !== "") {
      const updatedOrganisationId =
        selectedOrganisation?.value ?? organisationId;

      if (!updatedOrganisationId) {
        throw new Error("No organisation ID provided.");
      }

      if (isUpdating) {
        if (!teamId) {
          alert("No team provided!");
          return;
        }

        await updateTeam.mutateAsync({
          teamId,
          organisationId: updatedOrganisationId,
          body: {
            ...team,
            name,
            description,
            organisationId: updatedOrganisationId,
          },
        });
      } else {
        await createTeam.mutateAsync({
          organisationId: updatedOrganisationId,
          body: {
            name,
            description,
            organisationId: updatedOrganisationId,
          },
        });
      }
    } else {
      alert("No name provided!");
    }

    onPrepareModalClose();
  };

  const [selectedOrganisation, setSelectedOrganisation] = useState<{
    value: string;
    label: string;
  } | null>();

  const onPrepareModalClose = () => {
    setName(undefined);
    setDescription(undefined);
    onClose();
  };

  const onDeleteTeam = async () => {
    if (team) {
      const teamId = team.id;
      const organisationId = team.organisation?.id;

      if (!teamId || !organisationId) {
        throw new Error("Team ID or Organisation ID is missing.");
      }

      await deleteTeam.mutateAsync({ organisationId, teamId });
      navigate("/teams");
    } else {
      throw new Error("No team to delete.");
    }
  };

  const loadOrganisations = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    organisationsApi
      .list(authTokens?.userId!, inputValue)
      .then((organisations) => {
        const result = organisations.map((organisation) => {
          return {
            label: organisation.name ?? "No Organisation Name",
            value: organisation.id!,
          };
        });

        callback(result);
      });
  };

  const message = selectedOrganisation?.value == null ? "You must select an organization" : (isUpdating
    ? getPermissionMessage("edit_team", team?.id)
    : getPermissionMessage("create_team", selectedOrganisation?.value));

  const hasPermission = isUpdating
    ? getPermission("edit_team", team?.id)
    : getPermission("create_team", selectedOrganisation?.value);

  return (
    <>
      <Modal
        size={"lg"}
        isCentered
        isOpen={isOpen}
        onClose={onPrepareModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {`${isUpdating ? "Update" : "Create"}`} Team
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack width={"100%"} spacing={4}>
              <TextInput
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                placeholder="Name"
                label="Name"
                id="name"
              />
              <TextInput
                type="textarea"
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                placeholder="Description"
                label="Description"
                id="description"
              />
              {!organisationId && (
                <AsyncSelect
                  styles={customSelectStyles}
                  isClearable
                  placeholder="Select Organisation"
                  value={selectedOrganisation}
                  onChange={(value) => setSelectedOrganisation(value)}
                  cacheOptions
                  defaultOptions={true}
                  loadOptions={loadOrganisations}
                />
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"space-between"}>
              {isUpdating ? (
                <Button
                  rightIcon={<TrashSimple />}
                  colorScheme="red"
                  variant="outline"
                  mr={3}
                  isLoading={deleteTeam.isLoading}
                  onClick={onDeleteTeam}
                >
                  Delete
                </Button>
              ) : (
                <Box />
              )}
              <Flex>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Close
                </Button>
                <Tooltip
                  label={message}
                  isDisabled={hasPermission}
                  shouldWrapChildren
                >
                  <Button
                    isLoading={createTeam.isLoading || updateTeam.isLoading}
                    isDisabled={!hasPermission}
                    onClick={onModifyTeam}
                    colorScheme="blue"
                  >
                    {`${isUpdating ? "Update" : "Create"}`} Team
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
