import axios from "axios";
import shallow from "zustand/shallow";
import { AuthTokens, useAuthStore } from "../Store/AuthStore";
import jwt from "jwt-decode";
import { isBefore, add, isAfter } from "date-fns";

const useMethods = () => {
  const { authTokens, setAuthTokens } = useAuthStore(
    (state) => ({
      setAuthTokens: state.setAuthTokens,
      authTokens: state.authTokens,
    }),
    shallow
  );

  const getConfig = async () => {
    if (authTokens) {
      let { accessToken, refreshToken } = authTokens;

      const decodedAccessToken = jwt(accessToken) as { exp: number };
      const { exp } = decodedAccessToken;
      const expirationDate = new Date(exp * 1000);

      const currentTime = new Date();
      const currentTimeAddFiveMinutes = add(currentTime, {
        minutes: 5,
      });

      if (isBefore(expirationDate, currentTimeAddFiveMinutes)) {
        // Need to refresh the token...
        try {
          const newAccessTokens = (
            await axios.get(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
              headers: {
                Authorization: "Bearer " + refreshToken,
              },
            })
          ).data as AuthTokens;

          // Update the access token...
          accessToken = newAccessTokens.accessToken;
          // Set the new access token, refresh token, and userId.
          setAuthTokens(newAccessTokens);
        } catch (e) {
          console.error("Could not refresh token.");
        }
      }

      return {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    return {};
  };

  const upload = async (url: string, data: any, onProgress: (progress: number) => void) => {
    try {
      const config = await getConfig();
      return axios.post(url, data, {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total!) * 100);
        onProgress(progress); // Call the progress callback with the percentage
      },
      })
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  const post = async (url: string, data: any) => {
    try {
      const config = await getConfig();
      return axios.post(url, data, config);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const patch = async (url: string, data: any) => {
    try {
      const config = await getConfig();
      return axios.patch(url, data, config);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const deleteOne = async (url: string, params?: any) => {
    try {
      const config = await getConfig();
      return axios.delete(url, { ...config, ...params });
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const get = async (url: string) => {
    try {
      const config = await getConfig();
      return axios.get(url, config);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const save = async (url: string) => {
    try {
      const config = await getConfig();

    return axios({
      url,
      method: "GET",
      responseType: "blob",
      ...config,
    }).then((response) => {
      const filename = response.headers["content-disposition"]?.split("=")[1]!;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return {
    get,
    post,
    deleteOne,
    patch,
    save,
    upload
  };
};

export default useMethods;
