import { Td } from "@chakra-ui/react";
import styles from "./Table.module.scss";
import { RenderRowOptions } from "./Table";

export const StandardTableRow = ({
  column,
  columnIndex,
  row,
  rowIndex,
  totalRows,
}: RenderRowOptions) => {
  const value = row[column.accessor] as string;

  return (
    <Td
      maxW={"200px"}
      overflow={"hidden"}
      textOverflow={"ellipsis"}
      whiteSpace={"nowrap"}
      key={`${rowIndex}-${columnIndex}`}
      borderBottom={rowIndex === totalRows - 1 ? 0 : undefined}
      isNumeric={column.isNumeric}
      className={`${column.isLink ? styles.link : ""}`}
    >
      {value}
    </Td>
  );
};
