import {
    Button,
    Icon,
    Input,
    VStack,
    Text,
    Box,
    Progress,
} from "@chakra-ui/react";
import { useState } from "react";
import styles from "./AvatarUpload.module.scss";
import useUsersApi from "../../../../../Api/Resources/Users/UsersApi";
import { User } from "../../../../../Api/Resources/Users/UsersApiTypes";
import { Camera, Person, PersonSimple, User as UserIcon, UserCircle } from "phosphor-react";

const AvatarUpload = (props: { user?: User }) => {
    const { user } = props;
    const { uploadProfilePicture } = useUsersApi();

    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [progress, setProgress] = useState<number>(0);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);

            // Create preview URL for the selected image
            const objectUrl = URL.createObjectURL(selectedFile);
            setPreviewUrl(objectUrl);
        }
    };

    const handleUpload = async () => {
        const userId = user?.id;

        if (file && userId) {
            await uploadProfilePicture(file, userId, (progress) => {
                setProgress(progress);
            });
        } else {
            alert("Please select a file first.");
        }
    };

    const imageExists = previewUrl || user?.avatarMedium;

    return (
        <VStack className={styles.container} spacing={4}>
            <Box
                className={styles.avatarWrapper}
                position="relative"
                onClick={() => document.getElementById("fileInput")?.click()}
            >
                {imageExists ? (
                    <img
                        className={styles.avatar}
                        src={previewUrl || user?.avatarMedium}
                        alt="User Avatar"
                    />
                ) : (
                    <Box className={styles.placeholder}>
                        <UserIcon weight="light" size={180} />
                    </Box>
                )}
                <Box className={styles.overlay}>
                    <Icon as={Camera} boxSize={8} color="white" />
                </Box>
                <Input
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                    variant="unstyled"
                    accept="image/*"
                    hidden
                />
            </Box>
            {file && (
                <Button
                    onClick={handleUpload}
                    colorScheme="blue"
                    size="sm"
                    className={styles.uploadButton}
                >
                    Upload
                </Button>
            )}
            {progress > 0 && progress < 100 && (
                <VStack width="100%" spacing={2}>
                    <Progress value={progress} size="sm" width="100%" colorScheme="blue" />
                </VStack>
            )}
        </VStack>
    );
};

export default AvatarUpload;
