import { useState } from 'react';
import { FormControl, FormLabel, Input, Textarea, Button, useToast } from "@chakra-ui/react";
import useContactApi from '../../Api/Resources/Contact/ContactApi';
import TitledPage from '../../Components/TitledPage';

const ContactUsPage = ({
    isLoggedIn = false,
    title = "Contact Us",
    infoMessage = "If you have any questions or feedback, please feel free to contact us using the form below."
}: {
    isLoggedIn?: boolean;
    title?: string;
    infoMessage?: string;
}) => {
    const { sendMessage } = useContactApi();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const toast = useToast();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!name || !email || !message) {
            toast({
                title: "Please fill in all fields.",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const result = await sendMessage({ body: { name, email, message } });

            if (result) {
                toast({
                    title: "Message sent successfully!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setName('');
                setEmail('');
                setMessage('');
            } else {
                const errorMessage = "Failed to send message.";

                toast({
                    title: "Error sending message.",
                    description: errorMessage,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error sending message.",
                description: "A network error occurred. Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.error("Fetch error:", error);
        }
    };

    return (
        <>
            <TitledPage
                isResponsive={true}
                title={title}
                action={[]}
            >
                <div style={{ margin: !isLoggedIn ? '100px 0 50px 0' : '20px 0 50px 0' }}>
                    <p style={{ margin: '0 0 30px 0' }}>
                        {infoMessage}
                    </p>
                    <form onSubmit={handleSubmit}> {/* Add form element */}
                        <FormControl id="name" mb={4} isRequired>
                            <FormLabel>Name</FormLabel>
                            <Input type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                        </FormControl>
                        <FormControl id="email" mb={4} isRequired>
                            <FormLabel>Email</FormLabel>
                            <Input type="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </FormControl>
                        <FormControl id="message" mb={6} isRequired>
                            <FormLabel>Message</FormLabel>
                            <Textarea placeholder="Your Message" rows={6} value={message} onChange={(e) => setMessage(e.target.value)} />
                        </FormControl>
                        <Button type="submit" colorScheme="blue" size="lg">
                            Send Message
                        </Button>
                    </form>
                </div>
            </TitledPage>
        </>
    );
};

export default ContactUsPage;