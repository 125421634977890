import {
  Button,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import { PencilSimple, Placeholder, Plus } from "phosphor-react";
import useCategoriesApi from "../../../../Api/Resources/Categories/CategoriesApi";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Category } from "../../../../Api/Resources/Categories/CategoriesApiTypes";
import { useMemo, useState } from "react";
import CustomTable, { ColumnType } from "../../../../UIKit/Table/Table";
import { ModifyCategoryModal } from "./ModifyCategoryModal";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";
import { ButtonTableRow } from "../../../../UIKit/Table/ButtonTableRow";
import { useUserId } from "../../../../Store/AuthStore";
import Loading from "../../../../UIKit/Loading/Loading";
import { usePermissions } from "../../../../Store/PermissionStore";

const CategoriesTab = () => {
  const categoryModal = useDisclosure();
  const categoriesApi = useCategoriesApi();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const userId = useUserId();
  const { projectId, teamId, organisationId } = useParams();
  const { getPermission } = usePermissions();

  const updateCategoryPermission = getPermission("update_user_category", projectId) || getPermission("update_team_category", teamId);

  const { data: categories, isFetching: isFetchingCategories } = useQuery(
    ["categories", projectId],
    () => {
      if (projectId) {
        return categoriesApi.list({
          projectId,
          userId,
          teamId,
          organisationId,
        });
      }

      return;
    }
  );

  const columns = useMemo(() => {
    let items: any = [
      { label: "Name", accessor: "name" },
      { label: "Billable", accessor: "billable" },

    ]

    if (updateCategoryPermission) {
      items.push({
        label: "Edit",
        accessor: "edit",
        type: ColumnType.BUTTON,
        isNumeric: true,
      })
    }

    return items;
  }, [updateCategoryPermission])

  if (isFetchingCategories) {
    return <Loading />;
  }

  return (
    <>
      <ModifyCategoryModal
        projectId={projectId!}
        category={selectedCategory}
        isOpen={categoryModal.isOpen}
        onClose={() => {
          setSelectedCategory(undefined);
          categoryModal.onClose();
        }}
      />

      <Card isPadded={false}>
        <CardHeader
          title="Categories"
          subtitle="Manage categories for this project."
          action={
            updateCategoryPermission ? <Button
              leftIcon={<Plus weight="bold" />}
              variant={"outline"}
              colorScheme={"blue"}
              onClick={categoryModal.onOpen}
            >
              New Category
            </Button> : <></>
          }
        />

        <CustomTable
          placeholder={{
            label: "No categories",
            icon: Placeholder,
          }}
          renderRow={(options) => {
            switch (options.column.type) {
              case ColumnType.BUTTON: {
                return (
                  <ButtonTableRow
                    icon={PencilSimple}
                    {...options}
                    onItemClicked={(options) => {
                      const { rowIndex } = options;
                      const category = categories![rowIndex];
                      setSelectedCategory(category);
                      categoryModal.onOpen();
                    }}
                  />
                );
              }
              default: {
                return <StandardTableRow {...options} />;
              }
            }
          }}
          rows={
            categories?.map((category) => {
              return {
                name: category.name,
                billable: category.billable ? "Yes" : "No",
              };
            }) ?? []
          }
          columns={columns}
        />
      </Card>
    </>
  );
};

export default CategoriesTab;
