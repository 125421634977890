import {
  Button,
  useDisclosure,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { PencilSimple } from "phosphor-react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import useTeamsApi from "../../../Api/Resources/Teams/TeamsApi";
import TitledPage from "../../../Components/TitledPage";
import { ModifyTeamModal } from "../../Account/Pages/Organisations/Components/ModifyTeamModal";
import { TeamMembersTab } from "./Components/TeamMembersTab";
import { TeamProjectsTab } from "./Components/TeamProjectsTab";
import TeamEntriesTab from "./Components/TeamEntriesTab";
import { usePermissions } from "../../../Store/PermissionStore";
import { useMemo } from "react";

const ViewTeamPage = () => {
  const { teamId, organisationId } = useParams();
  const teamsApi = useTeamsApi();
  const editTeamModal = useDisclosure();
  const { getPermission } = usePermissions();

  const teamProjectsPermission = getPermission("get_team_projects", teamId)
  const teamEntriesPermission = getPermission("get_team_entries", teamId)

  const { data: team, isFetching: isFetchingTeams } = useQuery(
    ["teams", teamId],
    () => {
      if (teamId && organisationId) {
        return teamsApi.get({
          teamId,
          organisationId,
        });
      }
    }
  );

  const tabs = useMemo(() => {
    let items = ["Team"]

    if (teamProjectsPermission) {
      items.push("Projects")
    }

    if (teamEntriesPermission) {
      items.push("Entries")
    }

    return items;
  }, [teamEntriesPermission, teamProjectsPermission])

  return (
    <TitledPage
      breadcrumbs={[{ label: "Teams", link: "/teams" }]}
      isLoading={isFetchingTeams}
      title={team?.name ?? ""}
      subtitle={team?.description ?? ""}
      action={
        getPermission("edit_team", teamId!)
          ? {
            label: "Edit Team",
            icon: PencilSimple,
            trigger: editTeamModal.onOpen,
          }
          : undefined
      }
    >
      <Tabs marginTop={"12px"} isLazy>
        <TabList>
          {tabs.map((tab) => {
            return (<Tab>{tab}</Tab>)
          })}
        </TabList>
        <TabPanels>
          <TabPanel paddingX={0}>
            <TeamMembersTab team={team} />
          </TabPanel>
          <TabPanel paddingX={0}>
            <TeamProjectsTab team={team} />
          </TabPanel>
          <TabPanel paddingX={0}>
            <TeamEntriesTab team={team} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <ModifyTeamModal
        team={team}
        organisationId={team?.organisation?.id}
        isOpen={editTeamModal.isOpen}
        onClose={editTeamModal.onClose}
      />
    </TitledPage>
  );
};

export default ViewTeamPage;
