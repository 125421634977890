import React, { useState, useRef } from 'react';
import styles from './CollapseExpand.module.scss';

interface CollapseExpandProps {
    children: React.ReactNode;
    className?: string;
    isOpen?: boolean
}

const CollapseExpand: React.FC<CollapseExpandProps> = ({ children, className, isOpen = false }) => {
    return (
        <div className={`${styles.container ?? ""} ${className ?? ""} ${isOpen ? styles.open : ''}`} >
            {children}
        </div>
    );
};

export default CollapseExpand;
