import "./index.css";
import App from "./App";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import ListProjectsPage from "./Pages/Projects/List/ListProjectsPage";
import EntriesPage from "./Pages/Entries/List/ListEntriesPage";
import ViewProjectPage from "./Pages/Projects/View/ViewProjectPage";
import LoginPage from "./Pages/Login/LoginPage";
import shallow from "zustand/shallow";
import { useAuthStore } from "./Store/AuthStore";
import { AccountPage } from "./Pages/Account/AccountPage";
import SettingsPage from "./Pages/Account/Pages/Settings/SettingsPage";
import ListOrganisationsPage from "./Pages/Account/Pages/Organisations/ListOrganisationsPage";
import ViewOrganisationPage from "./Pages/Account/Pages/Organisations/View/ViewOrganisationPage";
import ListTeamsPage from "./Pages/Teams/List/ListTeamsPage";
import HomeLanding from "./Pages/HomeLanding/HomeLanding";
import ViewTeamPage from "./Pages/Teams/View/ViewTeamPage";
import ViewPersonPage from "./Pages/Users/View/ViewUserPage";
import UnauthorisedPage from "./Pages/Unauthorised/UnauthorisedPage";
import ViewPersonalDetails from "./Pages/Account/Pages/PersonalDetails/ViewPersonalDetails";
import ResetPasswordPage from "./Pages/Login/ResetPasswordPage";
import VerifyEmailPage from "./Pages/Login/VerifyEmailPage";
import AboutUsPage from "./Pages/Marketing/AboutUsPage";
import ContactUsPage from "./Pages/Marketing/ContactUs";
import PrivacyPolicyPage from "./Pages/Marketing/PrivacyPolicyPage";
import TermsOfServicePage from "./Pages/Marketing/TermsOfServicePage";
import WhatsNewPage from "./Pages/Marketing/WhatsNewPage";

const authenticatedRoutes = [
    {
        path: "",
        element: <EntriesPage />,
    },
    {
        path: "projects",
        element: <ListProjectsPage />,
    },
    {
        path: "projects/:projectId",
        element: <ViewProjectPage />,
    },
    {
        path: "organisations/:organisationId/teams/:teamId/projects/:projectId",
        element: <ViewProjectPage />,
    },
    {
        path: "teams",
        element: <ListTeamsPage />,
    },
    {
        path: "app-contact",
        element: <ContactUsPage isLoggedIn />,
    },
    {
        path: "app-support",
        element: <ContactUsPage isLoggedIn title={"Support"} infoMessage="If you need help with the app, please feel free to contact us using the form below." />,
    },
    {
        path: "people/:personId",
        element: <ViewPersonPage />,
    },
    {
        path: "organisations/:organisationId/teams/:teamId",
        element: <ViewTeamPage />,
    },
    {
        path: "account",
        element: <AccountPage />,
    },
    {
        path: "account/personal-details",
        element: <ViewPersonalDetails />,
    },
    {
        path: "account/settings",
        element: <SettingsPage />,
    },
    {
        path: "account/organisations",
        element: <ListOrganisationsPage />,
    },
    {
        path: "organisations/:organisationId",
        element: <ViewOrganisationPage />,
    },
    {
        path: "unauthorised",
        element: <UnauthorisedPage />,
    },
];

const publicRoutes = [
    { path: "landing", element: <HomeLanding /> },
    { path: "about-us", element: <AboutUsPage /> },
    { path: "contact-us", element: <ContactUsPage /> },
    { path: "privacy-policy", element: <PrivacyPolicyPage /> },
    { path: "terms-of-service", element: <TermsOfServicePage /> }
];

export const Router = () => {
    const { authTokens } = useAuthStore(
        (state) => ({ authTokens: state.authTokens }),
        shallow
    );

    const isDesktopApp = (window as any).electron?.isDesktopApp || false;
    const isLoggedIn = authTokens?.accessToken !== undefined;

    const router = createBrowserRouter([
        {
            path: "/",
            element: isLoggedIn ? (
                <App showFooter={false} isLoggedIn={true} />
            ) : (
                isDesktopApp ? <Navigate to="/sign-up" /> : <Navigate to="/landing" />
            ),
            children: authenticatedRoutes,
        },
        {
            path: "/login",
            element: isLoggedIn ? <Navigate to="/" /> : <App showPageHeader={false} showFooter={false} showNav={false} isLoggedIn={false} />,
            children: [{ path: "", element: <LoginPage /> }],
        },
        {
            path: "/whats-new",
            element: <App showPageHeader={false} showFooter={false} showNav={false} isLoggedIn={false} />,
            children: [{ path: "", element: <WhatsNewPage /> }],
        },
        {
            path: "/sign-up",
            element: isLoggedIn ? <Navigate to="/" /> : <App showPageHeader={false} showFooter={false} showNav={false} isLoggedIn={false} />,
            children: [{ path: "", element: <LoginPage isSignUp={true} /> }],
        },
        {
            path: "/verify-email",
            element: <App showPageHeader={false} showFooter={false} showNav={false} isLoggedIn={false} />,
            children: [{ path: "", element: <VerifyEmailPage /> }],
        },
        {
            path: "/reset-password",
            element: <App showPageHeader={false} showFooter={false} showNav={false} isLoggedIn={false} />,
            children: [{ path: "", element: <ResetPasswordPage /> }],
        },
        {
            path: "/",
            element: isLoggedIn ? <Navigate to="/" /> : <App showPageHeader={false} showFooter={false} isLoggedIn={false} />,
            children: publicRoutes,
        },
    ]);

    return <RouterProvider router={router} />;
};